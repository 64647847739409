import React from 'react';
import { Layout, Seo } from 'components';

// import page style
import './404.scss';

const NotFoundPage = () => (
  <Layout indication="hcp" className="hcp-404">
    <Seo pageTitle="HCP page not found!" />
    <h1>NOT FOUND</h1>
    <p>Sorry, this page doesn't exist!</p>
  </Layout>
);

export default NotFoundPage;
